<template>
  <b-card>
    <FormBuilder
      label="User"
      :fields="fields"
      :loading-submit="$store.state.user.loadingSubmit"
      submit-label="Submit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'
import debounce from 'lodash/debounce'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  computed: {
    fields() {
      return [
        {
          key: 'type',
          label: 'Transaction',
          type: 'radio',
          required: true,
          options: [
            { value: 'transfer', text: 'Transfer' },
            { value: 'deduct', text: 'Deduct' },
          ],
        },
        {
          key: 'userID',
          label: 'User',
          option_key: 'id',
          type: 'select',
          text: 'text',
          required: true,
          actionSearch: 'user/search',
        },
        {
          key: 'walletType',
          label: 'Wallet type',
          type: 'radio',
          required: true,
          options: [
            { value: 'credit', text: 'Wallet (Gank gold)' },
            { value: 'earning', text: 'Earnings (USD)' },
          ],
        },
        {
          key: 'amount',
          label: 'Amount',
          type: 'number',
          notes: 'The amount of money in USD or gank gold',
          required: true,
        },
        {
          key: 'title',
          label: 'Title',
          notes: 'It will show up in their transaction records.',
          required: true,
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
          minLength: 5,
        },
      ]
    },
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('transfer/transfer', data)
        .then(callback)
    },
    searchUser(keyword, setLoading) {
      if (!keyword.length) {
        return
      }

      setLoading(true)
      this.loadUserData(keyword, setLoading)
    },
    loadUserData: debounce(function search(keyword, setLoading) {
      this.$store.dispatch('transfer/getUserData', keyword)
        .finally(() => {
          setLoading(false)
        })
    }, 500),
  },
}
</script>

<style scoped>

</style>
